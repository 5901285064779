import { APP_CONFIG, LOGIN_URL } from "@crema/constants/AppConfig";
import { message } from "antd";
import { SupportChannelEnum, UserRoleEnum } from "./AppEnums";
export const DATA_LOGIN_KEY = "dataLogin";
export const MAX_IMAGE_INLINE_SIZE = 500 * 1024; // 500KB in bytes
export const MAX_SEND_MESSAGE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
export const EDITOR_ALLOWED_FORMATS = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "code-block",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
  "image",
];

export const AI_TYPES = [
  {
    id: "aiagent",
    label: "Basic Agent",
  },
  {
    id: "aiagentproxy",
    label: "Proxy Agent",
  },
  {
    id: "kbagent",
    label: "Knowledge Base Agent",
  },
  {
    id: "facilitator",
    label: "Data Collector",
  },
  {
    id: "ops",
    label: "Operations",
  },
];
export const KNOWLEDGE_BASE_SYNC_STATUS = Object.freeze({
  SYNCING: "SYNCING",
  SYNCED: "SYNCED",
});
export const ITEM_STATUS = Object.freeze({
  ACTIVE: "A",
  INACTIVE: "I",
});
export const FunctionTypeEnum = Object.freeze({
  REST: "rest",
  SIMPLE_REST: "simple-rest",
  OPENAPI_REST: "openapi-rest",
});

export const FunctionAuthGrantType = Object.freeze({
  CLIENT_CREDENTIALS: "clientCredentials",
  PASSWORD: "password",
  TOKEN_EXCHANGE: "tokenExchange",
});

export const FunctionAuthTypeEnum = Object.freeze({
  BASIC: "BASIC",
  BEARER: "BEARER",
  OAUTH2: "OAUTH2",
});

export const HttpMethodEnum = Object.freeze({
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
});

export const ContentTypeEnum = Object.freeze({
  JSON: "application/json",
  FORM: "application/x-www-form-urlencoded",
  TEXT: "text/plain",
  FORM_DATA: "multipart/form-data",
});

export const FUNCTION_TYPE_OPTIONS = [
  {
    id: FunctionTypeEnum.SIMPLE_REST,
    name: "Simple REST",
  },
  {
    id: FunctionTypeEnum.OPENAPI_REST,
    name: "OpenAPI REST",
  },
];

export const FUNCTION_AUTH_TYPES_OPTIONS = [
  {
    id: FunctionAuthTypeEnum.BASIC,
    name: "Basic Auth",
  },
  {
    id: FunctionAuthTypeEnum.BEARER,
    name: "Bearer Token",
  },
  {
    id: FunctionAuthTypeEnum.OAUTH2,
    name: "OAuth 2.0",
  },
];

export const FUNCTION_AUTH_GRANT_TYPE_OPTIONS = [
  {
    id: FunctionAuthGrantType.PASSWORD,
    name: "Password",
  },
  {
    id: FunctionAuthGrantType.CLIENT_CREDENTIALS,
    name: "Client Credentials",
  },
  {
    id: FunctionAuthGrantType.TOKEN_EXCHANGE,
    name: "Token Exchange",
  },
];
export const HTTP_METHOD_OPTIONS = [
  {
    id: HttpMethodEnum.GET,
    name: "GET",
  },
  {
    id: HttpMethodEnum.POST,
    name: "POST",
  },
  {
    id: HttpMethodEnum.PUT,
    name: "PUT",
  },
  {
    id: HttpMethodEnum.DELETE,
    name: "DELETE",
  },
];

export const SORT_DIRECTION = {
  ASC: "ASC",
  DESC: "DESC",
};

export const AI_BOT_TYPES = {
  PRE: "PRE-PROCESSING",
  PROCESSING: "PROCESSING",
  POST: "POST-PROCESSING",
};

export const AGENT_SOCKET_URL = process.env.REACT_APP_INTERNAL_WS_URL;
export const CLIENT_SOCKET_URL = process.env.REACT_APP_EXTERNAL_WS_URL;

export const RULE_TYPES = {
  MAINTAIN_ROUTING: "DEFAULT",
  AI_ACTIVE_TIME: "AI_BUSINESS_HOURS_ONLY",
  GROOVY: "GROOVY",
};

export const EDITOR_CONFIGS_BY_SUPPORT_CHANNEL = {
  DEFAULT: {
    toolbar: null,
    modules: {
      toolbar: null,
      // The matchVisual option in the Quill clipboard module configuration is used to adjust how pasted content is visually integrated into the Quill editor.
      // When this option is set to true, Quill tries to match the visual appearance of the pasted content to the current formatting of the editor.
      // If set to false, Quill pastes the content more directly, preserving the original formatting of the pasted content as much as possible.
      clipboard: {
        matchVisual: true,
      },
    },
    formats: [],
    messageTypeMapping: {
      simpleText: true,
      html: false,
      markdown: false,
    },
  },
  [SupportChannelEnum.TELEGRAM]: {
    modules: {
      toolbar: null,
      // The matchVisual option in the Quill clipboard module configuration is used to adjust how pasted content is visually integrated into the Quill editor.
      // When this option is set to true, Quill tries to match the visual appearance of the pasted content to the current formatting of the editor.
      // If set to false, Quill pastes the content more directly, preserving the original formatting of the pasted content as much as possible.
      clipboard: {
        matchVisual: true,
      },
    },
    formats: [],
    messageTypeMapping: {
      simpleText: true,
      html: false,
      markdown: false,
    },
  },
  [SupportChannelEnum.ZALO]: {
    modules: {
      toolbar: null,
      // The matchVisual option in the Quill clipboard module configuration is used to adjust how pasted content is visually integrated into the Quill editor.
      // When this option is set to true, Quill tries to match the visual appearance of the pasted content to the current formatting of the editor.
      // If set to false, Quill pastes the content more directly, preserving the original formatting of the pasted content as much as possible.
      clipboard: {
        matchVisual: true,
      },
    },
    formats: [],
    messageTypeMapping: {
      simpleText: true,
      html: false,
      markdown: false,
    },
  },
  [SupportChannelEnum.TEAMS]: {
    modules: {
      toolbar: [
        [
          "bold",
          "italic",
          "underline",
          "strike",
          { header: 1 },
          { header: 2 },
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
          "blockquote",
          "code-block",
          "link",
          "image",
          "clean",
        ],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
    formats: EDITOR_ALLOWED_FORMATS,
    messageTypeMapping: {
      simpleText: true,
      html: true,
      markdown: false,
    },
  },
  [SupportChannelEnum.WEBCHAT]: {
    modules: {
      toolbar: [
        [
          "bold",
          "italic",
          "underline",
          "strike",
          { header: 1 },
          { header: 2 },
          { list: "ordered" },
          { list: "bullet" },
          "blockquote",
          "code-block",
          "link",
          "image",
          "clean",
        ],
      ],
      clipboard: {
        matchVisual: true,
      },
    },
    formats: EDITOR_ALLOWED_FORMATS,
    messageTypeMapping: {
      simpleText: true,
      html: false,
      markdown: true,
    },
  },
  [SupportChannelEnum.TEST]: {
    modules: {
      toolbar: [
        [
          "bold",
          "italic",
          "underline",
          "strike",
          { header: 1 },
          { header: 2 },
          { list: "ordered" },
          { list: "bullet" },
          "blockquote",
          "code-block",
          "link",
          "image",
          "clean",
        ],
      ],
      clipboard: {
        matchVisual: true,
      },
    },
    formats: EDITOR_ALLOWED_FORMATS,
    messageTypeMapping: {
      simpleText: true,
      html: false,
      markdown: true,
    },
  },
  [SupportChannelEnum.FACEBOOK]: {
    modules: {
      toolbar: [
        [
          "bold",
          "italic",
          "underline",
          "strike",
          { header: 1 },
          { header: 2 },
          { list: "ordered" },
          { list: "bullet" },
          "blockquote",
          "code-block",
          "link",
          "image",
          "clean",
        ],
      ],
      clipboard: {
        matchVisual: true,
      },
    },
    formats: [],
    messageTypeMapping: {
      simpleText: true,
      html: false,
      markdown: true,
    },
  },
  [SupportChannelEnum.EMAIL]: {
    modules: {
      toolbar: [
        [
          "bold",
          "italic",
          "underline",
          "strike",
          { header: 1 },
          { header: 2 },
          { list: "ordered" },
          { list: "bullet" },
          "blockquote",
          "code-block",
          "link",
          "image",
          "clean",
        ],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
    formats: EDITOR_ALLOWED_FORMATS,
    messageTypeMapping: {
      simpleText: true,
      html: true,
      markdown: false,
    },
  },
};
export const CHATBOT_TOKEN = "XBOT_token";
export const BOT_DEFAULT_AVATAR_URL = "/assets/icon/bot-avatar.svg";
export const XFLOW_VIEW_EDITOR_DATASET_KEY = "XFLOW_VIEW_EDITOR_DATASET_KEY";
export const USER_DEFAULT_AVATAR_URL =
  "/assets/images/avatar/default-avatar.jpg";
export const HTTP_FETCHING_TIMEOUT_DEFAULT = 30000;
export const MESSAGE_EXPIRED_TIME = 10 * 60 * 1000; // 10 minutes im ms
export const RoutePermittedRole = {
  [UserRoleEnum.SUPER_ADMIN]: UserRoleEnum.SUPER_ADMIN,
  [UserRoleEnum.ADMIN]: UserRoleEnum.ADMIN,
  [UserRoleEnum.MANAGER]: UserRoleEnum.MANAGER,
  [UserRoleEnum.AGENT]: UserRoleEnum.AGENT,
};
export const MAX_TOKENS = 8192;
export const MAX_TEMPERATURE = 10;
export const SUPPORT_CHANNEL_CONFIG = {
  [SupportChannelEnum.UNKNOWN]: {
    name: "UNKNOWN",
    urlPath: "",
  },
  [SupportChannelEnum.WEBCHAT]: {
    name: "Webchat",
    urlPath: "",
  },
  [SupportChannelEnum.EMAIL]: {
    name: "Email",
    urlPath: "",
  },
  [SupportChannelEnum.MAILSERVER]: {
    name: "Mail Server",
    urlPath: "",
  },
  [SupportChannelEnum.TEST]: {
    name: "Test",
    urlPath: "",
  },
  [SupportChannelEnum.VOICETWILIO]: {
    name: "Voice",
    urlPath: "",
  },
  [SupportChannelEnum.MOBILE]: {
    name: "Mobile",
    urlPath: "",
  },
  [SupportChannelEnum.ZALO]: {
    name: "Zalo",
    urlPath: "/assets/images/zalo-logo.svg",
  },
  [SupportChannelEnum.TELEGRAM]: {
    name: "Telegram",
    urlPath: "/assets/images/telegram.png",
  },
  [SupportChannelEnum.FACEBOOK]: {
    name: "Facebook",
    urlPath: "/assets/images/facebook.png",
  },
  [SupportChannelEnum.TEAMS]: {
    name: "Teams",
    urlPath: "/assets/images/teams-logo.svg",
  },
};

export const SUPPORT_CHANNEL_LIST_OPTION = [
  { id: "WEBCHAT", label: "WEBCHAT" },
  { id: "EMAIL", label: "EMAIL" },
  { id: "ZALO", label: "ZALO" },
  { id: "MAILSERVER", label: "MAILSERVER" },
  { id: "MSTEAMS", label: "MSTEAMS" },
  { id: "FACEBOOK", label: "FACEBOOK" },
  { id: "TELEGRAM", label: "TELEGRAM" },
  { id: "TEST", label: "TEST" },
];

export const FILE_MAX_SIZE = 5 * 1024 * 1024;
export const FILE_MAX_SELECTED_AT_TIME = 5;
export const FILE_ACCEPTED_TYPES =
  "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, text/plain, application/json, image/*";
export const IMAGE_MIME_TYPES = {
  "image/jpeg": "image/jpeg",
  "image/png": "image/png",
  "image/gif": "image/gif",
  "image/webp": "image/webp",
  "image/svg+xml": "image/svg+xml",
  image: "image",
};

export const FILE_ATTACHMENT_TYPES = {
  image: "image",
  file: "file",
  link: "link",
};

export const TIME_UNITS_BY_LOCALE = {
  en: ["second(s)", "minute(s)", "hour(s)", "day(s)", "month(s)", "year(s)"],
  vi: ["giây", "phút", "giờ", "ngày", "tháng", "năm"],
  // Add more locales as needed
};
export const RoleLevel = Object.freeze({
  [UserRoleEnum.SUPER_ADMIN]: 4,
  [UserRoleEnum.ADMIN]: 3,
  [UserRoleEnum.MANAGER]: 2,
  [UserRoleEnum.AGENT]: 1,
});

export const userListPivotPagingObject = (appCode) => ({
  url: userFetchingObject?.pivotPagingByApp,
  params: {
    startRow: 0,
    endRow: 30,
    rowGroupCols: [],
    valueCols: [],
    pivotCols: [],
    pivotMode: false,
    groupKeys: [],
    filterModel: {
      clientType: {
        filter: 1,
        type: "equals",
        filterType: "text",
      },
    },
    sortModel: [],
    appCode,
  },
});

export const userListPivotCountObject = (appCode) => ({
  url: userFetchingObject?.pivotCountByApp,
  params: {
    startRow: 0,
    endRow: 30,
    rowGroupCols: [],
    valueCols: [],
    pivotCols: [],
    pivotMode: false,
    groupKeys: [],
    filterModel: {
      clientType: {
        filter: 1,
        type: "equals",
        filterType: "text",
      },
    },
    sortModel: [],
    appCode,
  },
});
export const AI_TYPE = Object.freeze({
  AGENT: "PROCESSING",
  PRE: "PRE-PROCESSING",
  POST: "POST-PROCESSING",
});

export const MESSAGE_CATEGORY = Object.freeze({
  AI_NO_ANSWER: "AI_NO_ANSWER",
  HANDOFF: "HANDOFF",
});

export const DateRangeTypeEnum = Object.freeze({
  TODAY: "today",
  YESTERDAY: "yesterday",
  LAST_7_DAYS: "last7days",
  LAST_WEEK: "lastweek",
  THIS_WEEK: "thisweek",
  LAST_MONTH: "lastmonth",
  MONTH_TO_DATE: "monthtodate",
  THIS_MONTH: "thismonth",
  LAST_30_DAYS: "last30days",
  LAST_YEAR: "lastyear",
  YEAR_TO_DATE: "yeartodate",
  CUSTOM: "custom",
  DEFAULT: "default",
});
export const authRole = Object.freeze({
  [UserRoleEnum.SUPER_ADMIN]: [
    "ROLE_XCHATBOT_SUPER_ADMIN",
    "ROLE_XCHATBOT_ADMIN",
    "ROLE_XCHATBOT_MANAGER",
    "ROLE_XCHATBOT_AGENT",
  ],
  [UserRoleEnum.ADMIN]: [
    "ROLE_XCHATBOT_ADMIN",
    "ROLE_XCHATBOT_MANAGER",
    "ROLE_XCHATBOT_AGENT",
  ],
  [UserRoleEnum.MANAGER]: ["ROLE_XCHATBOT_MANAGER", "ROLE_XCHATBOT_AGENT"],
  [UserRoleEnum.AGENT]: ["ROLE_XCHATBOT_AGENT"],
});

export const roleName = Object.freeze({
  [UserRoleEnum.SUPER_ADMIN]: "Super Admin",
  [UserRoleEnum.ADMIN]: "Admin",
  [UserRoleEnum.MANAGER]: "Group Manager",
  [UserRoleEnum.AGENT]: "Agent",
});

export const defaultUser = {
  displayName: "John Alex",
  email: "demo@example.com",
  token: "access-token",
  role: "user",
  photoURL: "/assets/images/avatar/A11.jpg",
};
export const allowMultiLanguage = process.env.REACT_APP_MULTILINGUAL === "true";
export const fileStackKey = process.env.REACT_APP_FILESTACK_KEY;
export const initialUrl = process.env.REACT_APP_INITIAL_URL; // this url will open after login

export const randomColors = [
  "#FF5733",
  "#C70039",
  "#900C3F",
  "#581845",
  "#FFC300",
  "#DAF7A6",
  "#FFABAB",
  "#FFC3A0",
  "#D9A7C7",
  "#ACD8AA",
  "#FFE156",
  "#FFDAC1",
  "#BDE0FE",
  "#B4DFE5",
  "#D6E2E9",
  "#D3E0DC",
  "#FEC8D8",
  "#D4A5A5",
  "#392F5A",
  "#31A2AC",
  "#61C0BF",
  "#6B4226",
  "#D9BF77",
];

export const userFetchingObject = {
  pivotPagingByApp: APP_CONFIG.MCR_XPSECMT + "user/pivotPagingByApp",
  changePassword: APP_CONFIG.MCR_XPSECMT + "user/changePass",
  pivotCount: APP_CONFIG.MCR_XPSECMT + "user/pivotCount",
  pivotCountByApp: APP_CONFIG.MCR_XPSECMT + "user/pivotCountByApp",
  uploadProfilePhoto: LOGIN_URL.UPLOAD_PROFILE_PHOTO,
  downloadProfilePhoto: LOGIN_URL.DOWNLOAD_PROFILE_PHOTO + "?identifier=",
  downloadProfilePhotoByUserId:
    LOGIN_URL.DOWNLOAD_PROFILE_PHOTO_BY_USER_ID + "?userId=",
  create: APP_CONFIG.MCR_XPSECMT + "user/create",
  update: APP_CONFIG.MCR_XPSECMT + "user/update",
  delete: APP_CONFIG.MCR_XPSECMT + "user/enabled",
};

export const availableBotFetchingObject = {
  pivotPaging: APP_CONFIG.MCR_XPCBR + "config/bot/pivotPagingForSelect",
  pivotCount: APP_CONFIG.MCR_XPCBR + "config/bot/pivotCountForSelect",
};

export const botFetchingObject = {
  pivotPaging: APP_CONFIG.MCR_XPCBR + "config/bot/pivotPaging",
  pivotCount: APP_CONFIG.MCR_XPCBR + "config/bot/pivotCount",
};

export const userRoleFetchingObject = {
  pivotPaging: APP_CONFIG.MCR_XPSECMT + "userRole/pivotPagingUsersOfRole",
  pivotCount: APP_CONFIG.MCR_XPSECMT + "userRole/pivotCountUsersOfRole",
};

export const reportPivotPagingUrl =
  APP_CONFIG.MCR_XPDBRP + "report/rpMessage/pivotPaging";
export const reportPivotCountUrl =
  APP_CONFIG.MCR_XPDBRP + "report/rpMessage/pivotCount";

export const infoViewContext = (successMsg = "Action successfully") => {
  const fetchSuccess = () => {
    message.success(successMsg);
  };

  const fetchError = () => {
    // message.error(errorMsg);
  };

  return {
    fetchSuccess,
    fetchError,
  };
};

export const APP_ACTION_TYPE = Object.freeze({
  OPEN_CONVERSATION_POPOUT: "OPEN_CONVERSATION_POPOUT",
});
